import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';

import { Translate, translate } from 'react-jhipster';
import { Button, Label, Alert, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import Illustration1 from 'app/component/illustration-1';
import HeaderPublic from 'app/shared/layout/header-public/header';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';
import jwt_decode from 'jwt-decode';
import { Brand } from 'app/shared/layout/header-public/header-components';

export interface ILoginProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{}> {}

export const Loginx = (props: ILoginProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { height, width } = useWindowDimensions();
  const handleSubmit = ({ username, password }) => {
    username = 'api@example.com';
    password = 'sylius-api';
    handleLogin(username, password);
  };

  const handleLogin = (username, password, rememberMe = false) =>
    props.login(username, password, rememberMe);

  const { location, isAuthenticated } = props;
  const { from } = (location.state as any) || {
    from: { pathname: '/', search: location.search },
  };

  console.log(`cek autentikasi ${isAuthenticated}`);
  if (isAuthenticated) {
    // var token =
    //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIzMTAzMTQ2Iiwic2NvcGUiOlsib3BlbmlkIl0sImV4cCI6MTY2MTc2MTAzNywiaWF0IjoxNjYxNzYwNzM3LCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiXSwianRpIjoiZ0RNbDFiaGk5ZnllSWRidkxFV3JCeS1GZWk0IiwiY2xpZW50X2lkIjoid2ViX2FwcCJ9.Y2ZV2kvuijQ-HLt4A742Nz6zYu0zlGO2KskNwA4oTcWHg5oDZYOs9ShUqafIcaAQw8BhA2kd0awiRIWLJOSSwwhxoC8_fr1hqm6zmuIBh8knr8BB5uIBPVYMOAgolteMozEGQEZbJix4CmCq-wVncmDlIUGnof_SAJTPkijn_aVwyStESQvzKoQb45rf-0NPNSiOGjbU40BA-dksHm2dPqujcggLLM1ArUv7nCTo1i7mSghp48kXW0xdSFPyMnYuJS9DgKPcyVZ5mT8_x0bj1VudmbvHlHIuioNaOhY3LWQbtcABSFJquL0OnBG-ZIm1rFk-3AhsKjd6PE0FbnZtiw';
    // var decoded = jwt_decode(token);
    // console.log('DECODE ENCRYPT', decoded);

    return <Redirect to={from} />;
  }
  return (
    <>
      {props.isAuthenticated ? <HeaderPublic /> : <></>}
      <div
        style={
          width < 425
            ? {
                height: '90vh',
                background: '#ffffff',
                overflowY: 'scroll',
                opacity: '0.9',
              }
            : {
                height: '70vh',
                width: '120vh',
                background: '#ffffff',
                opacity: '0.9',
              }
        }
        className="mt-5 py-5 container-md rounded"
      >
        <AvForm onSubmit={handleSubmit}>
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Col md="8" className="py-5 px-4">
              <Row>
                <Col lg="12" md="12">
                  <div className="d-flex justify-content-end align-items-end">
                    <Brand />
                  </div>
                  <br />
                  {/* <br /> */}
                  <AvField
                    name="email"
                    label={translate('global.form.email.label')}
                    placeholder={translate(
                      'global.form.email.placeholder'
                    )}
                    required
                    errorMessage="Email cannot be empty!"
                    autoFocus
                  />
                  <AvField
                    name="password"
                    label={translate('login.form.password')}
                    placeholder={translate(
                      'login.form.password.placeholder'
                    )}
                    required
                    type={showPassword ? 'text' : 'password'}
                    errorMessage="Password cannot be empty!"
                  />
                  {!showPassword ? (
                    <EyeSmallIconDark
                      style={{
                        position: 'absolute',
                        top: '85%',
                        right: '5%',
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <EyeSmallIconDarkHide
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        position: 'absolute',
                        top: '84%',
                        right: '5%',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </Col>
              </Row>

              {/* <div className="float-right text-right px-0">
                <Link
                  to="/account/reset/request"
                  style={{ color: '#F37F26' }}
                  className="my-2"
                >
                  Forget Password ?
                </Link>
              </div> */}
              <Button
                color="#F37F26"
                type="submit"
                className="input-block-level form-control h2 text-white font-weight-bold mt-4 rounded"
                style={{
                  backgroundColor: '#F37F26',
                  borderColor: '#F37F26',
                  color: '#F37F26',
                }}
              >
                <Translate contentKey="login.form.button">
                  Sign in
                </Translate>
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Loginx);
