import React, { useEffect } from 'react';
import classNames from 'classnames';

import '../../../../../../../scss/app.min.scss';

const SideBar = ({ isOpen, toggle }) => {
  // const { height, width } = useWindowDimensions();
  // const [openDropdown, setOpenDropdown] = useState<any>(null);
  // const [showKaryawan, setShowKaryawan] = useState<any>(false);
  // const [canUpload, setCanUpload] = useState<any>(false);
  // const [canCreate, setCanCreate] = useState<any>(false);

  useEffect(() => {
    // isAdminHC()
    // iscanCreate()
  }, []);

  // const iscanCreate = () => {
  //   Axios.get(`/services/uaadocservice/api/is-user-may-create`)
  //     .then(async (res) => {
  //       console.log('response', res.data);

  //       if (res.data) {
  //         setCanCreate(true);
  //       } else {
  //         setCanCreate(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setCanCreate(false);
  //     });
  // };

  // const isAdminHC = () => {
  //   Axios.get(`/services/uaadocservice/api/is-admin-hc`)
  //     .then(async (res) => {
  //       console.log('response', res.data);

  //       if (res.data) {
  //         setShowKaryawan(true);
  //       } else {
  //         isAdminDMS();
  //       }
  //     })
  //     .catch((err) => {
  //       isAdminDMS();
  //     });
  // };

  // const isAdminDMS = () => {
  //   Axios.get(`/services/uaadocservice/api/is-admin-dms`)
  //     .then(async (res) => {
  //       if (res.data) {
  //         setShowKaryawan(true);
  //       } else {
  //         setShowKaryawan(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setShowKaryawan(false);
  //     });
  // };

  // const handleLogout = async () => {
  // await logout()
  // await history.replace('/')
  // await delayTime(2000)
  // await toast.success('Berhasil Logout', {
  //   position: "top-left",
  //   autoClose: 8000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   });
  // };

  return (
    <div
      className={classNames('sidebar', { 'is-open': isOpen })}
      style={{ display: 'block' }}
    >
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: '#fff' }}>
          &times;
        </span>
      </div>
      <div
        className="vertical-menu"
        id="sidebar-menu"
        style={isOpen ? { display: 'block' } : {}}
      >
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <a href="/" className=" waves-effect">
                  <i className="bx bx-home-alt"></i>
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="/donation/order" className=" waves-effect">
                  <i className="bx bx-user"></i>
                  <span>Donation report by order</span>
                </a>
              </li>
              <li>
                <a
                  href="/donation/merchant"
                  className=" waves-effect"
                >
                  <i className="bx bx-file"></i>
                  <span>Donation report merchant</span>
                </a>
              </li>
              <li>
                <a href="/shipping/upload" className=" waves-effect">
                  <i className="bx bx-bar-chart-square"></i>
                  <span>Shipping Upload</span>
                </a>
              </li>
              <hr className="mx-4" />
              <li>
                <a href="/logout" className=" waves-effect">
                  <i className="bx bx-log-in-circle"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
