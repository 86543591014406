import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';

import './home.scss';

// const list = [
//   { value: 2 },
//   { value: 5 },
//   { value: 10 },
//   { value: 15 },
//   { value: 20 },
//   { value: 25 },
// ];

import React, { useEffect, useState } from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { IRootState } from 'app/shared/reducers';

// import {
//   getSearchEntities,
//   getEntities,
//   getEntitiesByEmpId,
//   getDocumentApproval,
//   getDocumentAcknowledge,
//   getDocumentSuratKeluar,
//   getDocumentSuratMasuk,
//   getDocumentSuratRejected,
// } from './../../entities/document/document.reducer';
import Axios from 'axios';
import IllustrationEmpty from 'app/component/illustration-empty';

// export interface IDocumentProps
//   extends StateProps,
//     DispatchProps,
//     RouteComponentProps<{ url: string }> {}

export const Home = () => {
  // const { account } = props;

  // const [category, setCategory] = useState<any>(0);
  // const [page, setPage] = useState<any>(1);
  // const [limit, setLimit] = useState<any>(5);
  // const [selected, setSelected] = useState(list[0]);
  // const [listNotification, setListNotification] = useState<any>([]);
  // const [modalBanner, setModalBanner] = useState<any>(false);
  // const [banner, setBanner] = useState<any>(null);

  // const state = {
  //   labels: [
  //     'Register Customer',
  //     'Approved Customer',
  //     'Reject Customer',
  //   ],
  //   datasets: [
  //     {
  //       label: 'Customer Presentation',
  //       backgroundColor: ['#F37F26', '#43936C', '#CB3A31'],
  //       hoverBackgroundColor: ['#c5671f', '#9d2d26', '#175000'],
  //       data: [180, 80, 100],
  //     },
  //   ],
  // };

  // const state2 = {
  //   labels: ['BCA', 'Sinarmas', 'BRI'],
  //   datasets: [
  //     {
  //       label: 'Rainfall',
  //       backgroundColor: ['#002F48', '#731912', '#3267E3'],
  //       hoverBackgroundColor: ['#002031', '#50110c', '#22479c'],
  //       data: [121, 59, 161],
  //     },
  //   ],
  // };

  useEffect(() => {}, []);

  Axios.interceptors.response.use((response) => {
    console.log('Getting Response:', response);
    return response;
  });

  // const { documentList, loading } = props;

  return (
    <div
      className="px-4"
      style={{ height: '100%', background: '#fff' }}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Home</h1>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-center">
          <IllustrationEmpty />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  // documentList: document.documentDashboardList,
  // documentListAcknowledge: document.documentListAcknowledge,
  // documentListSuratMasuk: document.documentListSuratMasuk,
  // documentListSuratKeluar: document.documentListSuratKeluar,
  // documentListSuratRejected: document.documentListSuratRejected,
  // loading: document.loading,
  // totalApproval: document.totalApproval,
  // totalSuratMasuk: document.totalSuratMasuk,
  // totalAcknowledge: document.totalAcknowledge,
  // totalSuratKeluar: document.totalSuratKeluar,
  // totalPageApproval: document.totalPageApproval,
  // totalPageAcknowledge: document.totalPageAcknowledge,
  // totalPageKotakMasuk: document.totalPageKotakMasuk,
  // totalPageKotakKeluar: document.totalPageKotakKeluar,
  // totalPageKotakRejected: document.totalPageKotakRejected,
  // totalSuratRejected: document.totalSuratRejected,
});

const mapDispatchToProps = {
  // getSearchEntities,
  // getEntities,
  // getEntitiesByEmpId,
  // getDocumentApproval,
  // getDocumentAcknowledge,
  // getDocumentSuratKeluar,
  // getDocumentSuratMasuk,
  // getDocumentSuratRejected,
};
// type StateProps = ReturnType<typeof mapStateToProps>;
// type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
