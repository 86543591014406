import axios from 'axios';
import { Storage } from 'react-jhipster';

import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'app/shared/reducers/action-type.util';
import { setLocale } from 'app/shared/reducers/locale';
import { toast } from 'react-toastify';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
};

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (
  state: AuthenticationState = initialState,
  action
): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.LOGOUT):
      const isAuthenticated = true;
      toast.success('Berhasil Logout', {
        position: 'top-left',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return {
        ...initialState,
        isAuthenticated,
        showModalLogin: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = true;
      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data,
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export const displayAuthError = (message) => ({
  type: ACTION_TYPES.ERROR_MESSAGE,
  message,
});

export const getSession: () => void = () => async (
  dispatch,
  getState
) => {
  const token = Storage.session.get('token');

  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get(
      'https://dev-commerce.miraeasset.io/api/v2/admin/administrators',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  });

  const { account } = getState().authentication;
  if (account) {
    await dispatch(setLocale('en'));
  } else {
    toast.success('Berhasil Logout', {
      position: 'top-left',
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

// export const login: (username: string, password: string, rememberMe?: boolean) => void = (username, password, rememberMe = false) => async (
//   dispatch,
//   getState
// ) => {
//   const result = await dispatch({
//     type: ACTION_TYPES.LOGIN,
//     payload: axios.post('auth/employee-auth', { username, password, isEmployee: true }),
//   });
//   await dispatch(getSession());
// };

export const login: (
  email: string,
  password: string,
  rememberMe?: boolean
) => void = (email, password) => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post(
      'https://dev-commerce.miraeasset.io/api/v2/admin/authentication-token',
      { email, password }
    ),
  });
  Storage.session.set('token', result.value.data.token);
  await dispatch(getSession());
};

export const logout: () => void = () => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.LOGOUT,
    payload: axios.post('auth/logout', {}),
  });

  // fetch new csrf token
  dispatch(getSession());
};

export const clearAuthentication = (messageKey) => (
  dispatch,
  getState
) => {
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};
